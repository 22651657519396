<template>
  <div class="w-100 my-5">
    <div class="row mx-auto">
      <div class="col-sm m-2 box-white d-flex flex-column">
        <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('sales_amount') }}</span>
        <span class="mt-3 font-bold font-50 color-blue">{{ getPriceFormat(salesAmount) }}</span>
        <div class="d-flex justify-content-between">
          <span class="font-12 alpha-07">{{ $t('paid_online') }}</span>
          <span class="font-15 color-blue">{{ getPriceFormat(paidOnline) }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span class="font-12 alpha-07">{{ $t('paid_by_card') }}</span>
          <span class="font-15 color-blue">{{ getPriceFormat(paidCard) }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span class="font-12 alpha-07">{{ $t('paid_by_cash') }}</span>
          <span class="font-15">{{ getPriceFormat(paidCash) }}</span>
        </div>
      </div>
      <div class="col-sm m-2 box-white d-flex flex-column">
        <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('tip') }}</span>
        <span class="mt-3 font-bold font-50 color-blue">{{ getPriceFormat(tipAmount) }}</span>
        <div class="d-flex justify-content-between">
          <span class="font-12 alpha-07">{{ $t('paid_online') }}</span>
          <span class="font-15 color-blue">{{ getPriceFormat(tipOnline) }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span class="font-12 alpha-07">{{ $t('paid_by_card') }}</span>
          <span class="font-15 color-blue">{{ getPriceFormat(tipCard) }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span class="font-12 alpha-07">{{ $t('paid_by_cash') }}</span>
          <span class="font-15">{{ getPriceFormat(tipCash) }}</span>
        </div>
      </div>
      <div class="col-sm m-2 box-white d-flex flex-column">
        <span class="fa fa-euro-sign font-15 color-red">&nbsp;&nbsp;&nbsp;{{ $t('handling_fee') }}</span>
        <span class="mt-3 font-bold font-50 color-red">{{ getPriceFormat(handlingFee) }}</span>
        selectedTag = [{{ selectedTag }}]
      </div>
    </div>
    <div class="row mx-auto">
      <div class="col-sm m-2 box-white row vertical-center">
        <div class="col-sm my-1 vertical-center" v-if="organizerInfo">
          <img class="circle-image-30" :src="organizerInfo.thumbnailUrl || organizerInfo.photoUrl || assets.profile" />
          <span class="ml-2 font-15 font-bold">{{ organizerInfo.userName }}</span>
        </div>
        <div class="col-sm my-1 vertical-center" v-else-if="eventInfo">
          <img :width="60" :height="40" :src="eventInfo.thumbnailUrl || eventInfo.photoUrl" />
          <span class="ml-2 font-15 font-bold">{{ eventInfo.name }}</span>
        </div>
        <div class="col-sm my-1 vertical-center">
          <span class="font-bold font-15 alpha-07">{{ $t('orders') }}:</span>
          <span class="mx-5 font-bold font-20 color-blue" v-if="$route.params.organizerId || $route.params.eventId">{{ getDecimalFormat(items.length) }}</span>
          <span class="mx-5 font-bold font-20 color-blue" v-else>{{ `${getDecimalFormat(items.length)}${statsMap && statsMap['drink'] ? ` / ${getDecimalFormat(statsMap['drink'].orders)}` : ''}` }}</span>
        </div>
        <div class="col-sm my-1 vertical-center" v-if="eventInfo && eventInfo.tagList && eventInfo.tagList.length > 0">
          <span class="font-bold font-15 alpha-07">{{ $t('tag') }}</span>
          <b-form-select class="mx-5" v-model="selectedTag">
            <b-form-select-option v-for="tag in eventInfo.tagList" :value="tag" :key="tag">{{ tag }}</b-form-select-option>
          </b-form-select>
        </div>
        <DatePicker
          class="col-sm my-1"
          v-model="selectedMonth"
          type="month"
          @change="onMonthChanged"
          :placeholder="$t('select_month')"
        />
        <DatePicker
          class="col-sm my-1"
          v-model="dateRange"
          type="date"
          range
          @change="onDateRangeChanged"
          :placeholder="$t('select_date_range')"
        />
        <div :class="`col-sm my-1 vertical-center ${isMobile ? '' : 'justify-content-end'}`">
          <input class="w-75" v-model="keyword" />
          <v-btn class="ml-2" icon small @click="keyword = ''" :disabled="!keyword">
            <i :class="`flaticon-circle ${keyword.length > 0 ? 'color-blue' : ''}`"></i>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="row mx-auto" v-for="(item, index) in items" :key="(item, index)">
      <div class="col-sm m-2 p-0 box-white row">
        <div class="col-sm d-flex flex-column">
          <div class="my-1 vertical-center" v-if="!organizerInfo">
            <img class="circle-image-30" :src="item.organizerPhotoUrl" />
            <span class="ml-2 ellipsis font-bold font-12" :title="item.organizerName">{{ item.organizerName }}</span>
          </div>
          <img width="200px" :src="item.eventPhotoUrl" />
          <span class="my-1 w-250px ellipsis font-bold font-12" :title="item.eventName">{{ item.eventName }}</span>
        </div>
        <div class="vertical-center">
          <div class="my-5 div-divider-h" v-if="isMobile" />
          <div class="mx-3 div-divider-v" v-else />
        </div>
        <div class="col-sm-4 d-flex flex-column">
          <span class="m-2 font-12" v-if="hostname === 'localhost'">ID: {{ item.orderId }}</span>
          <span class="mt-3 font-bold font-15 color-red" v-if="item.tag">{{ $t('tag') }}: {{ item.tag }}</span>
          <span class="my-3 font-bold font-15 color-drink">Order No: {{ getDecimalFormat(item.orderNumber) }}</span>
          <div class="mt-2 d-flex justify-content-between vertical-center" v-for="(drink, index2) in item.drinks" :key="(drink, index2)">
            <span class="w-50 ellipsis font-12" :title="drink.name">{{ drink.name }}</span>
            <span class="ml-auto font-12">{{ `${getPriceFormat(drink.price)} * ${drink.quantity} = ${getPriceFormat(drink.price * drink.quantity)}` }}</span>
          </div>
          <div v-if="!isMobile">&nbsp;</div>
        </div>
        <div class="vertical-center">
          <div class="my-5 div-divider-h" v-if="isMobile" />
          <div class="mx-3 div-divider-v" v-else />
        </div>
        <div class="col-sm-3 d-flex flex-column">
          <div class="my-1 vertical-center">
            <img class="circle-image-30" :src="item.buyerPhotoUrl" />
            <span class="ml-2 ellipsis font-12 font-bold" :title="item.buyerName">{{ item.buyerName }}</span>
          </div>
          <span :class="`mt-2 ellipsis font-12 ${item.deviceTypeColor}`" :title="item.deviceType" v-if="item.deviceType">{{ item.deviceType }}</span>
          <div class="mt-5 d-flex justify-content-between">
            <span class="font-15">{{ $t('total_price') }}</span>
            <span class="ml-2 font-bold font-15">{{ getPriceFormat(item.totalPrice) }}</span>
          </div>
          <div class="mt-2 d-flex justify-content-between" v-if="item.tipPrice">
            <span class="font-12">{{ $t('tip') }}</span>
            <span class="ml-2 font-12">{{ getPriceFormat(item.tipPrice) }}</span>
          </div>
          <div class="mt-2 d-flex justify-content-between" v-if="item.handlingFee">
            <span class="font-12">{{ $t('handling_fee') }}</span>
            <span class="ml-2 font-12">{{ getPriceFormat(item.handlingFee) }}</span>
          </div>
        </div>
        <div class="vertical-center">
          <div class="my-5 div-divider-h" v-if="isMobile" />
          <div class="mx-3 div-divider-v" v-else />
        </div>
        <div class="col-sm d-flex flex-column">
          <div class="my-2 d-flex justify-content-between vertical-center" v-if="hostname === 'localhost' && item.eventId.startsWith(TEST_PREFIX)">
            <span class="my-2 font-bold font-15 color-red">{{ item.orderId.startsWith(PAYMENT_TYPE_PAYPAL_SANDBOX) || item.orderId.startsWith(PAYMENT_TYPE_STRIPE_TEST) ? 'Sandbox' : 'Test' }}</span>
            <v-btn icon small @click="deleteDrinkOrderInfoTask(item.orderId, item.eventId)">
              <i class="flaticon-delete color-red"></i>
            </v-btn>
          </div>
          <span class="mt-5 font-bold font-15 color-blue">{{ $t(item.orderId.startsWith(PAYMENT_TYPE_PAYPAL_SANDBOX) || item.orderId.startsWith(PAYMENT_TYPE_STRIPE_TEST) ? 'paid_online' : item.paymentMethod === PAYMENT_TYPE_CASH ? 'paid_by_cash' : item.paymentMethod === PAYMENT_TYPE_CARD ? 'paid_by_card' : 'paid') }}</span>
          <div class="mt-2 d-flex flex-column" v-if="hostname === 'localhost' && item.type">
            <span class="font-12 font-bold color-red">{{ item.type }}</span>
            <span class="ml-auto font-12">{{ item.orderId }}</span>
          </div>
          <div class="mt-2 d-flex vertical-center">
            <img height="30px" :src="item.orderId.startsWith(PAYMENT_TYPE_PAYPAL) ? assets.paypal : assets.stripe" v-if="item.orderId.startsWith(PAYMENT_TYPE_PAYPAL) || item.orderId.startsWith(PAYMENT_TYPE_STRIPE)" />
            <img height="30px" :src="item.paymentMethod === PAYMENT_TYPE_CASH ? assets.cash : assets.credit_card" v-else-if="item.paymentMethod" />
            <img class="ml-2" height="30px" :src="assets[item.paymentMethod]" v-if="item.orderId.startsWith(PAYMENT_TYPE_STRIPE) && item.paymentMethod" />
            <span class="ml-auto font-12">{{ getDateStringFromTimestamp(item.createdAt) }}</span>
          </div>
          <div class="mt-2" v-if="isMobile">&nbsp;</div>
        </div>
      </div>
    </div>
    <div class="my-5 d-flex justify-content-center" v-if="hasMore">
      <v-btn text small @click="getDrinkOrderListTask()">
        <span>Load More</span>
      </v-btn>
    </div>
    <div class="py-40 center" v-if="(items.length === 0)">
      <span class="font-20 alpha-07">{{ $t('no_data_found') }}</span>
    </div>
  </div>
</template>


<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import { httpsCallable } from 'firebase/functions';
import { functions, firestore } from '../../../main';
import { collection, query, where, orderBy, limit, startAfter, endBefore, getDocs, Timestamp } from 'firebase/firestore';
import { getPriceFormat, getDecimalFormat, getDateStringFromTimestamp, getDateStringFromDate, showLoading } from '../../../functions';

import profile from '@/assets/image/profile.jpg';
import paypal from '@/assets/image/paypal.png';
import stripe from '@/assets/image/stripe.png';
import cash from '@/assets/image/cash.png';
import credit_card from '@/assets/image/credit_card.png';
import card from '@/assets/image/card.png';
import sepa from '@/assets/image/sepa.png';
import sepa_debit from '@/assets/image/sepa.png';
import sofort from '@/assets/image/sofort.png';
import klarna from '@/assets/image/klarna.png';
import giropay from '@/assets/image/giropay.png';

export default {
  name: 'DrinkOrderList',
  components: {
    DatePicker
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    statsMap() {
      return this.$store.state.statsMap;
    },
    organizerList() {
      return this.$store.state.organizerList || [];
    },
    organizerInfo() {
      if (this.$route.params.organizerId) {
        return this.organizerList.find(element => element.userId === this.$route.params.organizerId);
      } else {
        return null;
      }
    },
    eventList() {
      return this.$store.state.eventList || [];
    },
    eventInfo() {
      if (this.$route.params.eventId) {
        return this.eventList.find(element => element.eventId === this.$route.params.eventId);
      } else {
        return null;
      }
    },
    params() {
      return this.$route.params;
    }
  },
  watch: {
    params() {
      this.keyword = '';
      this.drinkOrderList = [];
      this.getDrinkOrderListTask();
    },
    eventList(newList, oldList) {
      if (oldList.length !== newList.length) {
        this.refreshData();
      }
    },
    organizerList(newList, oldList) {
      if (oldList.length !== newList.length) {
        this.refreshData();
      }
    },
    drinkOrderList(newList, oldList) {
      const oldCount = oldList ? oldList.length : 0;
      const newCount = newList ? newList.length : 0;
      if (oldCount !== newCount) {
        this.refreshData();
      }
    },
    eventInfo() {
      this.selectedTag = this.eventInfo && this.eventInfo.tag ? this.eventInfo.tag : null;
    },
    selectedTag() {
      this.getDrinkOrderListTask();
    },
    keyword() {
      this.refreshData();
    }
  },
  data() {
    return {
      assets: {
        profile,
        paypal,
        stripe,
        credit_card,
        cash,
        card,
        sepa,
        sepa_debit,
        sofort,
        klarna,
        giropay
      },
      hostname: window.location.hostname,
      selectedMonth: null,
      dateRange: null,
      salesAmount: 0,
      paidOnline: 0,
      paidCard: 0,
      paidCash: 0,
      tipAmount: 0,
      tipOnline: 0,
      tipCard: 0,
      tipCash: 0,
      handlingFee: 0,
      hasMore: false,
      drinkOrderList: [],
      cacheEventList: [],
      cacheUserList: [],
      items: [],
      selectedTag: null,
      keyword: ''
    }
  },
  mounted() {
    this.cacheUserList = this.$store.state.userList;
    this.selectedTag = this.eventInfo && this.eventInfo.tag ? this.eventInfo.tag : null;
    this.getDrinkOrderListTask();
  },
  methods: {
    getPriceFormat,
    getDecimalFormat,
    getDateStringFromTimestamp,
    onMonthChanged(value, type) {
      this.dateRange = null;
      this.drinkOrderList = [];
      this.getDrinkOrderListTask();
    },
    onDateRangeChanged(value, type) {
      if (value && value.length === 2 && value[0] && value[1]) {
        this.selectedMonth = null;
      } else {
        this.selectedMonth = new Date();
      }
      this.drinkOrderList = [];
      this.getDrinkOrderListTask();
    },
    refreshData() {
      var salesAmount = 0;
      var paidOnline = 0;
      var paidCard = 0;
      var paidCash = 0;
      var tipAmount = 0;
      var tipOnline = 0;
      var tipCard = 0;
      var tipCash = 0;
      var handlingFee = 0;
      const items = [];
      const keyword = this.keyword.toLowerCase();
      this.drinkOrderList.filter(element => this.hostname === 'localhost' || !element.orderId.startsWith(this.PAYMENT_TYPE_PAYPAL_SANDBOX) || !element.orderId.startsWith(this.PAYMENT_TYPE_STRIPE_TEST)).forEach(orderInfo => {
        const item = JSON.parse(JSON.stringify(orderInfo));
        const organizerInfo = this.organizerList.find(element => element.userId === orderInfo.organizerId);
        const buyerInfo = this.cacheUserList.find(element => element.userId === orderInfo.buyerId) || organizerInfo;
        const eventInfo = this.getEventInfo(orderInfo.eventId);
        item['buyerName'] = orderInfo.buyerId ? (buyerInfo ? buyerInfo.userName : '') : orderInfo.employeeName || (buyerInfo ? buyerInfo.userName : '');
        item['buyerPhotoUrl'] = !orderInfo.buyerId && orderInfo.employeeName ? this.assets.profile : buyerInfo ? buyerInfo.thumbnailUrl || buyerInfo.photoUrl || this.assets.profile : this.assets.profile;
        item['organizerName'] = organizerInfo ? organizerInfo.userName : '';
        item['organizerPhotoUrl'] = organizerInfo ? organizerInfo.thumbnailUrl || organizerInfo.photoUrl || this.assets.profile : this.assets.profile;
        item['eventName'] = eventInfo ? eventInfo.name : '';
        item['eventPhotoUrl'] = eventInfo ? eventInfo.thumbnailUrl || eventInfo.photoUrl : '';
        if (orderInfo.buyerId && window.location.hostname === 'localhost') {
          item['deviceType'] = buyerInfo ? buyerInfo.deviceType : '';
          item['deviceTypeColor'] = buyerInfo ? buyerInfo.deviceTypeColor : '';
        }
        if (!keyword || !eventInfo || !buyerInfo || !organizerInfo ||
            (eventInfo && eventInfo.name.toLowerCase().includes(keyword)) ||
            (buyerInfo && buyerInfo.userName.toLowerCase().includes(keyword)) ||
            (organizerInfo && organizerInfo.userName.toLowerCase().includes(keyword))) {
          salesAmount += orderInfo.drinkPrice;
          tipAmount += orderInfo.tipPrice;
          handlingFee += orderInfo.handlingFee;
          if (orderInfo.paymentMethod === this.PAYMENT_TYPE_CARD) {
            paidCard += orderInfo.drinkPrice;
            tipCard += orderInfo.tipPrice;
          } else if (orderInfo.paymentMethod === this.PAYMENT_TYPE_CASH) {
            paidCash += orderInfo.drinkPrice;
            tipCash += orderInfo.tipPrice;
          } else if (orderInfo.referenceId) {
            paidOnline += orderInfo.drinkPrice;
            tipOnline += orderInfo.tipPrice;
          }
          items.push(item);
        }
      });
      items.sort((a, b) => (a.createdAt.seconds < b.createdAt.seconds) ? 1 : -1);
      this.items = items;
      this.salesAmount = salesAmount;
      this.paidOnline = paidOnline;
      this.paidCard = paidCard;
      this.paidCash = paidCash;
      this.tipAmount = tipAmount;
      this.tipOnline = tipOnline;
      this.tipCard = tipCard;
      this.tipCash = tipCash;
      this.handlingFee = handlingFee;
    },
    getEventInfo(eventId) {
      const cacheInfo = this.cacheEventList.find(element => element.eventId === eventId);
      if (cacheInfo) {
        return cacheInfo;
      } else {
        const eventInfo = this.eventList ? this.eventList.find(element => element.eventId === eventId) : null;
        if (eventInfo) {
          this.cacheEventList.push(eventInfo);
        }
        return eventInfo;
      }
    },
    getDrinkOrderListTask() {
      const startDate = new Date();
      const endDate = new Date();
      if (this.selectedMonth) {
        startDate.setTime(this.selectedMonth.getTime());
        endDate.setTime(this.selectedMonth.getTime());
        startDate.setMonth(startDate.getMonth() + 1);
      } else if (this.dateRange && this.dateRange.length > 1) {
        startDate.setTime(this.dateRange[1].getTime());
        endDate.setTime(this.dateRange[0].getTime());
        startDate.setDate(startDate.getDate() + 1);
      } else {
        endDate.setFullYear(2022);
      }
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      endDate.setHours(0);
      endDate.setMinutes(0);
      endDate.setSeconds(0);
      let startTimestamp;
      const endTimestamp = Timestamp.fromDate(endDate);
      if (this.drinkOrderList && this.drinkOrderList.length > 0) {
        const lastCreatedAt = this.drinkOrderList[this.drinkOrderList.length - 1].createdAt;
        if (lastCreatedAt && lastCreatedAt.seconds && lastCreatedAt.seconds * 1000 < startDate.getTime()) {
          startTimestamp = lastCreatedAt
        } else {
          startTimestamp = Timestamp.fromDate(startDate);
        }
      } else {
        startTimestamp = Timestamp.fromDate(startDate);
      }
      const loader = showLoading(this, 1);
      let q;
      if (this.$route.params.organizerId) {
        q = query(collection(firestore, 'drinkOrder'), where('organizerId', '==', this.$route.params.organizerId), orderBy('createdAt', 'desc'), startAfter(startTimestamp), endBefore(endTimestamp));
      } else if (this.$route.params.eventId) {
        if (this.selectedTag) {
          q = query(collection(firestore, 'drinkOrder'), where('eventId', '==', this.$route.params.eventId), where('tag', '==', this.selectedTag), orderBy('createdAt', 'desc'), startAfter(startTimestamp), endBefore(endTimestamp));
        } else {
          q = query(collection(firestore, 'drinkOrder'), where('eventId', '==', this.$route.params.eventId), orderBy('createdAt', 'desc'), startAfter(startTimestamp), endBefore(endTimestamp));
        }
      } else {
        q = query(collection(firestore, 'drinkOrder'), orderBy('createdAt', 'desc'), startAfter(startTimestamp), endBefore(endTimestamp), limit(this.PAGE_LIMIT_200));
      }
      getDocs(q).then(querySnapshot => {
        loader.hide();
        const list = [];
        var userIDs = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (data.paymentMethod && (this.hostname === 'localhost' || !data.eventId.startsWith(this.TEST_PREFIX))) {
            list.push(data);
            if (data.buyerId && !this.cacheUserList.find(element => element.userId === data.buyerId)) {
              userIDs.push(data.buyerId);
              if (userIDs.length >= 30) {
                this.getUserListTask(userIDs);
                userIDs = [];
              }
            }
          }
        });
        this.hasMore = this.$route.params.organizerId || this.$route.params.eventId ? false : querySnapshot.docs.length === this.PAGE_LIMIT_200;
        this.drinkOrderList = this.drinkOrderList.concat(list);
        this.refreshData();
        if (userIDs.length > 0) {
          this.getUserListTask(userIDs);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error('Unexpected error occurs - ' + error.message);
      });
    },
    getUserListTask(userIDs) {
      const q = query(collection(firestore, 'user'), where('userId', 'in', userIDs));
      getDocs(q).then(querySnapshot => {
        const list = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          const deviceType = data.deviceType || '';
          if (window.location.hostname === 'localhost') {
            data['deviceType'] = deviceType;
            if (deviceType.startsWith('Android : ')) {
              data['deviceTypeColor'] = 'color-red';
            } else if (deviceType.startsWith('iOS : ')) {
              data['deviceTypeColor'] = 'color-blue';
            } else if (deviceType.startsWith('Web : ')) {
              data['deviceTypeColor'] = 'color-purple';
            }
          } else {
            const myArray = deviceType.split(' : ');
            if (myArray && myArray.length > 0) {
              data['deviceType'] = myArray[0];
            } else {
              data['deviceType'] = '';
            }
            data['deviceTypeColor'] = 'color-primary';
          }
          if (data.userId === this.$route.params.userId) {
            this.userInfo = data;
          }
          list.push(data);
        });
        this.cacheUserList = this.cacheUserList.concat(list);
        this.$store.commit('set', ['userList', this.cacheUserList]);
        this.refreshData();
      });
    },
    deleteDrinkOrderInfoTask(orderId, eventId) {
      const params = {
        functionName: 'deleteDrinkOrderInfo',
        orderId: orderId,
        eventId: eventId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>